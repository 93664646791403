import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'avatar',
  standalone: true,
  imports: [TooltipModule, NgIf],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input({required:true}) name: string = '';
  @Input() image: string = '';
  @Input() size: string = '40'; // Default size is 10

  constructor() { }

  ngOnInit() {
  }

  protected getInitials(name: string): string {
    if (name) {
      const names = name.split(' ').filter(n => Boolean(n));
      if (names.length > 1) {
        return names[0][0].toUpperCase() + names[1][0].toUpperCase();
      } else {
        return names[0][0].toUpperCase();
      }
    }
    return '';
  }

  getFontSize(): string {
    return `${Number(this.size) * 0.4}px`;
  }
}

import { Routes } from '@angular/router';
import { SignGuardActivate, SignGuardDeActivate, SignGuardTempActivate } from '../components/sign/sign.guard';
import { AuthGuardActivation } from '../guards/auth.guard';
import { PermissionGuardActivation } from '../guards/permission.guard';

export const AppRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () =>
      import('../components/login/login.component').then(
        (m) => m.LoginComponent
      ),
  },
  {
    path: 'home',
    loadComponent: () =>
      import('../components/home/home.component').then((m) => m.HomeComponent),
    canActivate: [AuthGuardActivation],
    data: { animation: 'isLeft' },
  },
  {
    path: 'users',
    loadComponent: () =>
      import('../components/clients copy/users.component').then(
        (m) => m.UsersComponent
      ),
    canActivate: [AuthGuardActivation, PermissionGuardActivation],
  },
  // {
  //   path: 'deals',
  //   loadComponent: () =>
  //     import('../components/deals/deals.component').then(
  //       (m) => m.DealsComponent
  //     ),
  //   canActivate: [AuthGuardActivation],
  // },
  {
    path: 'profile',
    loadComponent: () =>
      import('../components/user-profile/user-profile.component').then(
        (m) => m.UserProfileComponent
      ),
    canActivate: [AuthGuardActivation]
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('../components/settings/settings.component').then(
        (m) => m.SettingsComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'new-property',
    loadComponent: () =>
      import('../components/new-asset/new-asset.component').then(
        (m) => m.NewAssetComponent
      ),
    canActivate: [AuthGuardActivation],
    data: { animation: 'isRight' },
  },
  {
    path: 'new-property/:id',
    loadComponent: () =>
      import('../components/new-asset/new-asset.component').then(
        (m) => m.NewAssetComponent
      ),
    canActivate: [AuthGuardActivation],
    data: { animation: 'isRight' },
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('../components/admin/admin.component').then(
        (m) => m.AdminComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'new-contract',
    loadComponent: () =>
      import('../components/create-contract/create-contract.component').then(
        (m) => m.CreateContractComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'contract/:clientId',
    loadComponent: () =>
      import('../components/create-contract/create-contract.component').then(
        (m) => m.CreateContractComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'contract/asset/:assetId',
    loadComponent: () =>
      import('../components/create-contract/create-contract.component').then(
        (m) => m.CreateContractComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'contracts',
    loadComponent: () =>
      import('../components/contracts/contracts.component').then(
        (m) => m.ContractsComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'properties',
    loadComponent: () =>
      import('../components/assets/assets.component').then(
        (m) => m.AssetsComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'property/:id',
    loadComponent: () =>
      import('../components/assets/sub-components/asset-details/asset-details.component').then(
        (m) => m.AssetDetailsComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  /* -------------------------------------------------------------------------- */
  /*                                   PUBLIC                                   */
  /* -------------------------------------------------------------------------- */
  {
    path: 'p-properties/company/:slug',
    loadComponent: () =>
      import('../components/public-assets/public-assets.component').then(
        (m) => m.PublicAssetsComponent
      ),
  },
  {
    path: 'p-properties/user/:slug',
    loadComponent: () =>
      import('../components/public-assets/public-assets.component').then(
        (m) => m.PublicAssetsComponent
      ),
  },
  {
    path: 'p-property/:publicId',
    loadComponent: () =>
      import('../components/assets/sub-components/asset-details/asset-details.component').then(
        (m) => m.AssetDetailsComponent
      ),
  },
  {
    path: 'p-property/:publicId/:userSlug',
    loadComponent: () =>
      import('../components/assets/sub-components/asset-details/asset-details.component').then(
        (m) => m.AssetDetailsComponent
      ),
  },
  {
    path: 'contract-templates',
    loadComponent: () =>
      import('../components/contract-templates/contract-templates.component').then(
        (m) => m.ContractTemplatesComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path:'clients',
    loadComponent: () =>
        import('../components/leads-and-clients/leads-and-clients.component').then(
            (m) => m.LeadsAndClientsComponent
        ),
        canActivate: [AuthGuardActivation],
  },
  {
    path: 'client/:id',
    loadComponent: () =>
      import('../components/clients/sub-components/client-edit/client-edit.component').then(
        (m) => m.ClientEditComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path:'new-activity',
    loadComponent: () =>
        import('../components/clients/sub-components/client-edit/sub-components/client-activities/sub-components/add-new-activity-after-google-auth/add-new-activity-after-google-auth.component').then(
            (m) => m.AddNewActivityAfterGoogleAuthComponent
        ),
        canActivate: [AuthGuardActivation],
  },
  {
    path: 'match-properties/:id',
    loadComponent: () =>
      import('../components/clients/sub-components/match-client-properties-details/match-client-properties-details.component').then(
        (m) => m.MatchClientPropertiesDetailsComponent
      ),
    canActivate: [AuthGuardActivation],
  },
  {
    path: 'sign-from-device/:id',
    loadComponent: () =>
        import('../components/sign/sign/sign.component').then(
            (m) => m.SignComponent
        ),
    canActivate: [AuthGuardActivation, SignGuardActivate],
  },
  {
    path: 'select-signer/:id',
    loadComponent: () =>
      import('../components/sign/sub-components/select-signer/select-signer.component').then(
        (m) => m.SelectSignerComponent
      ),
    canActivate: [],
  },
  {
    path: 'sign/:token',
    loadComponent: () =>
        import('../components/sign/sign/sign.component').then(
            (m) => m.SignComponent
        ),
    canActivate: [SignGuardTempActivate],
    canDeactivate: [SignGuardDeActivate],
  },
  {
    path: 'thank-you/:id/:exclusiveContractId',
    loadComponent: () =>
      import('../components/sign/sub-components/thank-you/thank-you.component').then(
        (m) => m.ThankYouComponent
      ),
    canActivate: [],
  },
  {
    path: 'thank-you/:id',
    loadComponent: () =>
      import('../components/sign/sub-components/thank-you/thank-you.component').then(
        (m) => m.ThankYouComponent
      ),
    canActivate: [],
  },
  {
    path: 'already-sign/:id',
    loadComponent: () =>
        import('../components/sign/sub-components/already-sign/already-sign.component').then(
            (m) => m.AlreadySignComponent
        )
  },
  {
    path: 'download-contract/:id',
    loadComponent: () =>
        import('../components/download-contract/download-contract.component').then(
            (m) => m.DownloadContractComponent
        )

  },

  {
    path: '**',
    redirectTo: 'home',
  },
];

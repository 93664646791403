@if(isAuth() && companyData){

<p-sidebar
	[modal]="false"
	[(visible)]="sidebarService.sidebarVisible"
	position="right"
	(onHide)="closeSidebar()"
>
	<ng-template
		pTemplate="header"
		class="flex flex-col gap-2 justify-center p-2 relative"
	>
		<div class="logo-container">
			@if(companyData && companyData?.logo){
			<p-avatar
				[image]="companyData.logo"
				styleClass="mr-2"
				size="xlarge"
				shape="circle"
			></p-avatar>
			}@else{
			<avatar [name]="companyData.name"></avatar>
			}
		</div>
	</ng-template>
	<div class="font-bold">
		<span>{{ companyData.name }}</span>
	</div>
	<div>
		<p-divider></p-divider>
		<div class="flex flex-col gap-2">
			<div *ngFor="let route of sidebarRoutes">
				<button
					(click)="navigate(route.routerLink)"
					type="button"
					class="w-full text-lg p-2 rounded-md flex justify-start gap-4 items-center bg-transparent text-gray-500 hover:bg-gray-200"
					[ngClass]="{'active-background': route.routerLink === activeRoute}"
				>
					<i [class]="route.icon"></i>
					<span>{{ route.label }}</span>
				</button>
			</div>
		</div>
	</div>

	<ng-template pTemplate="footer">
		<p-divider class="w-full"></p-divider>
		@if(showSettingsPanel){
		<div
			class="setting-panel"
			[@slideUpDown]="showSettingsPanel ? 'open' : 'close'"
		>
			<p-card>
				<p-menu [model]="settingsItems"></p-menu>
			</p-card>
		</div>
		}
		<button
			(click)="openSettingPanel()"
			type="button"
			class="user-profile-container"
		>
			<div class="user-profile-image">
				<avatar [name]="userDate?.displayName"></avatar>
			</div>
			<div class="user-profile-details">
				<div class="user-profile-name">{{ userDate?.displayName }}</div>
				@if(userDate && userDate.role && userDate.role === UserRole.Admin){
				<div class="user-profile-role">מנהל</div>
				}
			</div>
		</button>
	</ng-template>
</p-sidebar>

@if(!sidebarService.sidebarVisible()){
<div
	type="button"
	class="sidebar-btn"
	(click)="openSidebar()"
>
	<i class="fa-solid fa-bars text-xl"></i>
</div>
}
}

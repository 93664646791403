import { Directionality } from '@angular/cdk/bidi';
import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { Toast, ToastModule } from 'primeng/toast';
import { ToastService } from './components/global/snackbar/toast.service';
import { SidebarService } from './components/sidebar/sidebar.service';
import { AuthService } from './services/auth.service';
import { DeviceService } from './services/device.service';
import { NgStyle } from '@angular/common';
import { LogoComponent } from "./core/logo/logo.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, NgStyle, LogoComponent, SidebarComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})


export class AppComponent implements OnInit, AfterViewInit {
	@ViewChild('MessageAndSubToast') public MessageAndSubToast: Toast;
	@ViewChild('MessageToast') public MessageToast: Toast;

 

 
  protected isPublicUrl: boolean = false;
  constructor(protected sidebarService: SidebarService, protected authService: AuthService ,private primengConfig: PrimeNGConfig, injector: Injector, private dir: Directionality, private deviceService: DeviceService, private toastService: ToastService) {
    AppInjector = injector;
    this.isPublicUrl = window.location.pathname.includes('p-');
  }


 
  protected isMobile: boolean = false;

  ngOnInit() {
   this.isMobile = this.deviceService.isMobile()
    this.primengConfig.ripple = true;

    /* -------------------------------------------------------------------------- */
    /*                                  Z-INIDEX                                  */
    /* -------------------------------------------------------------------------- */
    this.primengConfig.zIndex = {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100   // tooltip
    };

    /* -------------------------------------------------------------------------- */
    /*                                   Filter                                   */
    /* -------------------------------------------------------------------------- */
    this.primengConfig.filterMatchModeOptions = {
      text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
      numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
      date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER]
  } ;
  }

  ngAfterViewInit(): void {
		this.toastService.MessageAndSubToast = this.MessageAndSubToast;
		this.toastService.MessageToast = this.MessageToast;
	}

  protected getMainContentPadding(): string {
    if(this.sidebarService.sidebarVisible()){
      return this.isMobile && this.dir.value === 'rtl' ? '0' : '16rem';
    }
    return '0';
}




  prepareRoutes(outlet: RouterOutlet){
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
